const messages = {
	es: {
	  translations: {
		signup: {
		  title: "Regístrate",
		  toasts: {
			success: "Usuario creado con éxito! ¡Haz tu inicio de sesión!",
			fail: "Error al crear usuario. Verifica los datos proporcionados.",
		  },
		  form: {
			name: "Nombre",
			email: "Correo electrónico",
			password: "Contraseña",
		  },
		  buttons: {
			submit: "Registrar",
			login: "¿Ya tienes una cuenta? ¡Ingresa!",
		  },
		},
		login: {
		  title: "Iniciar sesión",
		  form: {
			email: "Correo electrónico",
			password: "Contraseña",
		  },
		  buttons: {
			submit: "Entrar",
			register: "¿No tienes una cuenta? ¡Regístrate!",
		  },
		},
		companies: {
		  title: "Registrar Empresa",
		  form: {
			name: "Nombre de la Empresa",
			plan: "Plan",
			token: "Token",
			submit: "Registrar",
			success: "¡Empresa creada con éxito!",
		  },
		},
		auth: {
		  toasts: {
			success: "¡Inicio de sesión realizado con éxito!",
		  },
		  token: "Token",
		},
		dashboard: {
		  charts: {
			perDay: {
			  title: "Atenciones hoy: ",
			},
		  },
		},
		allowedConnections: {
			title: "Conexiones permitidas",
			buttons:{
				allow: "Permitir",
				disallow: "Denegar"
			},
			chips:{
				allow: "Permitido",
				disallow: "Denegado"
			},
			toasts: {
				allowed: "Conexión permitida con éxito!",
				disallowed: "Conexión denegada con éxito!",
				deleted: "Conexión eliminada con éxito!",
			},
			confirmationModal: {
				allowTitle: "Permitir",
				allowMessage: "Estás seguro? Esto puede conllevar problemas de seguridad.",
				disallowTitle: "Denegar",
				disallowMessage: "Estás seguro? Esto puede conllevar problemas de conexión.",
				deleteTitle: "Eliminar",
				deleteMessage: "Estás seguro? Esto no puede ser revertido.",
			},
			table: {
				id: "ID",
				address: "Dirección",
				status: "Estado",
				lastUpdate: "Última actualización",
				allowedBy: "Permitido por",
				actions: "Acciones"
			  },
		},
		connections: {
		  title: "Conexiones",
		  toasts: {
			deleted: "Conexión con WhatsApp eliminada con éxito!",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "¿Estás seguro? Esta acción no se puede revertir..",
			disconnectTitle: "Desconectar",
			disconnectMessage:
			  "¿Estás seguro? Tendrás que escanear el código QR de nuevo.",
		  },
		  buttons: {
			add: "Agregar WhatsApp",
			disconnect: "Desconectar",
			tryAgain: "Intentar de nuevo",
			qrcode: "CÓDIGO QR",
			newQr: "Nuevo CÓDIGO QR",
			connecting: "Conectando",
		  },
		  toolTips: {
			disconnected: {
			  title: "Error al iniciar sesión en WhatsApp",
			  content:
				"Asegúrate de que tu celular esté conectado a internet e intenta de nuevo, o solicita un nuevo Código QR.",
			},
			qrcode: {
			  title: "Esperando la lectura del código QR",
			  content:
				"Haga clic en el botón 'CÓDIGO QR' y escanee el Código QR con su celular para iniciar sesión.",
			},
			connected: {
			  title: "Conexión establecida.",
			},
			timeout: {
			  title: "Se perdió la conexión con el celular",
			  content:
				"Asegúrate de que tu celular esté conectado a internet y WhatsApp esté abierto, o haz clic en el botón 'Desconectar' para obtener un nuevo Código QR.",
			},
		  },
		  table: {
			name: "Nombre",
			status: "Estado",
			lastUpdate: "Última actualización",
			default: "Predeterminado",
			actions: "Acciones",
			session: "Sesión",
		  },
		},
		whatsappModal: {
		  title: {
			add: "Agregar WhatsApp",
			edit: "Editar WhatsApp",
		  },
		  form: {
			name: "Nombre",
			default: "Predeterminado",
			sendIdQueue: "Fila",
			timeSendQueue: "Redireccionar a la cola en X minutos",
			queueRedirection: "Redirección de Cola",
			queueRedirectionDesc: "Seleccione una fila para que los contactos que no tienen fila sean redirigidos.",
		    prompt: "Prompt",
			maxUseBotQueues: "Enviar chat x veces",
			timeUseBotQueues: "Tiempo para envio del chat",
			expiresTicket: "Cerrar chats expirados",
			expiresInactiveMessage: "Mensaje por inactividad",
			aceptedTicketMessage: "Mensaje por aceptar ticket",
  
		  },
		  buttons: {
			okAdd: "Añadir",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "WhatsApp guardado con éxito.",
		},
		qrCode: {
		  message: "Escanea el código QR para iniciar sesión",
		},
		contacts: {
		  title: "Contactos",
		  toasts: {
			deleted: "Contacto eliminado con éxito!",
		  },
		  searchPlaceholder: "Buscar...",
		  confirmationModal: {
			deleteTitle: "Eliminar",
			importTitlte: "Importar contactos",
			deleteMessage:
			  "¿Está seguro de que quiere eliminar este contacto? Todos los servicios relacionados se perderán.",
			importMessage: "¿Desea importar todos los contactos del teléfono?",
		  },
		  buttons: {
			import: "Importar Contactos",
			add: "Agregar Contacto",
		  },
		  table: {
			name: "Nombre",
			whatsapp: "WhatsApp",
			email: "Correo electrónico",
			actions: "Acciones",
		  },
		},
		syncContacts: {
		  title: "Sincronizar Contactos",
		  toasts: {
			deleted: "Contacto eliminado con éxito!",
		  },
		  searchPlaceholder: "Buscar...",
		  confirmationModal: {
			deleteTitle: "Eliminar",
			importTitlte: "Importar contactos",
			deleteMessage:
			  "¿Está seguro de que quiere eliminar este contacto? Todos los servicios relacionados se perderán.",
			importMessage: "¿Desea importar todos los contactos de Jireh?",
		  },
		  buttons: {
			import: "Importar Contactos",
			add: "Agregar Contacto",
		  },
		  table: {
			databaseId: "Base de Datos",
			clientId: "ID del Cliente",
			firstName: "Nombre",
			lastName: "Apellido",
			commercialName: "Nombre Comercial",
			whatsapp: "WhatsApp",
			address: "Dirección",
			latitude: "Latitud",
			longitude: "Longitud",
			email: "Correo electrónico",
			actions: "Acciones",
		  },
		},
		promptModal: {
		  form: {
			name: "Nombre",
			prompt: "Prompt",
			voice: "Voz",
			max_tokens: "Máximo de tokens en la respuesta",
			temperature: "Temperatura",
			apikey: "API Key",
			max_messages: "Límite máximo de mensajes en el Historial",
			voiceKey: "Clave de la API de Voz",
			voiceRegion: "Región de Voz",
		  },
		  success: "¡Prompt guardado con éxito!",
		  title: {
			add: "Añadir Prompt",
			edit: "Editar Prompt",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		},
		prompts: {
		  title: "Prompts",
		  table: {
			name: "Nombre",
			queue: "Sector/Fila",
			max_tokens: "Máximo de tokens por respuesta",
			actions: "Acciones",
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage: "¿Estás seguro? ¡Esta acción no se puede deshacer!",
		  },
		  buttons: {
			add: "Añadir Prompt",
		  },
		},
		contactModal: {
		  title: {
			add: "Añadir contacto",
			edit: "Editar contacto",
		  },
		  form: {
			mainInfo: "Datos de contacto",
			extraInfo: "Información adicional",
			name: "Nombre",
			number: "Número de Whatsapp",
			email: "Colorreo electrónico",
			extraName: "Nombre del campo",
			extraValue: "Valor",
		  },
		  buttons: {
			addExtraInfo: "Añadir información",
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Contacto guardado exitosamente.",
		},
		importedContactModal: {
		  title: {
			add: "Añadir contacto",
			edit: "Editar contacto",
		  },
		  form: {
			mainInfo: "Datos de contacto",
			extraInfo: "Información adicional",
			name: "Nombre",
			number: "Número de Whatsapp",
			email: "Colorreo electrónico",
			extraName: "Nombre del campo",
			extraValue: "Valor",
		  },
		  buttons: {
			addExtraInfo: "Añadir información",
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Contacto guardado exitosamente.",
		},
		queueModal: {
		  title: {
			add: "Añadir Departamentos",
			edit: "Editar Departamentos",
		  },
		  form: {
			name: "Nombre",
			color: "Color",
			greetingMessage: "Mensaje de saludo",
			complationMessage: "Mensaje de conclusión",
			outOfHoursMessage: "Mensaje fuera del horario de oficina",
			ratingMessage: "Mensaje de evaluación",
			token: "Token",
			orderQueue: "Orden de la fila (Bot)",
		    integrationId: "Integracion",
		  },
		  buttons: {
			okAdd: "Añadir",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		},
		userModal: {
		  title: {
			add: "Añadir usuario",
			edit: "Editar usuario",
		  },
		  form: {
			name: "Nombre",
			email: "Correo electrónico",
			password: "Contraseña",
			profile: "Perfil",
			whatsapp: "Conexión Predeterminada"
		  },
		  buttons: {
			okAdd: "Añadir",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "usuario guardado con éxito.",
		},
		scheduleModal: {
		  title: {
			add: "Nueva Programación",
			edit: "Editar Programación",
		  },
		  form: {
			body: "Mensaje",
			contact: "Contacto",
			sendAt: "Fecha de programación",
			sentAt: "Fecha de envío",
		  },
		  buttons: {
			okAdd: "Añadir",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Programación guardada con éxito.",
		},
		tagModal: {
		  title: {
			add: "Nueva Etiqueta",
			edit: "Editar etiqueta",
		  },
		  form: {
			name: "Nombre",
			color: "Color",
		  },
		  buttons: {
			okAdd: "Añadir",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Etiqueta guardada con éxito.",
		},
		chat: {
		  noTicketMessage: "Seleccione un ticket para empezar a conversar..",
		},
		uploads: {
		  titles: {
			titleUploadMsgDragDrop: "Arrastra y suelta archivos en el campo de abajo",
			titleFileList: "Listado de archivo(s)"
		  },
		},
		ticketsManager: {
		  buttons: {
			newTicket: "Nuevo",
		  },
		},
		ticketsQueueSelect: {
		  placeholder: "Departamentos",
		},
		tickets: {
		  toasts: {
			deleted: "El servicio en el que estaba fue eliminado.",
		  },
		  notification: {
			message: "Mensaje de",
		  },
		  tabs: {
			open: { title: "Abiertos" },
			closed: { title: "Resueltos" },
			search: { title: "Buscar" },
		  },
		  search: {
			placeholder: "Buscar atención y mensajes",
		  },
		  buttons: {
			showAll: "Todos",
		  },
		},
		transferTicketModal: {
		  title: "Transferir Ticket",
		  fieldLabel: "Escriba para buscar usuarios",
		  fieldQueueLabel: "Transferir a la fila",
		  fieldQueuePlaceholder: "Seleccione una fila",
		  noOptions: "Ningún usuario encontrado con ese nombre",
		  buttons: {
			ok: "Transferir",
			cancel: "Cancelar",
		  },
		},
		ticketsList: {
		  pendingHeader: "Esperando",
		  assignedHeader: "Asistiendo",
		  noTicketsTitle: "¡Aquí no hay nada!",
		  noTicketsMessage:
			"No se encontró ningún servicio con ese estado o término buscado.",
		  buttons: {
			accept: "Aceptar",
			closed: "Finalizar",
			reopen: "Reabrir"
		  },
		},
		newTicketModal: {
		  title: "Crear Ticket",
		  fieldLabel: "Escriba para buscar el Contacto",
		  add: "Añadir",
		  buttons: {
			ok: "Guardar",
			cancel: "Cancelar",
		  },
		},
		mainDrawer: {
		  listItems: {
			dashboard: "Tablero de control",
			connections: "Conexiones",
			tickets: "Atenciones",
			quickMessages: "Respuestas Rápidas",
			contacts: "Contactos",
			contactsSync: " Sincronizar Contactos",
			queues: "Departamentos & Chatbot",
			tags: "Etiquetas",
			administration: "Administración",
			users: "Usuarios",
			settings: "Configuraciones",
			helps: "Tutoriales",
			messagesAPI: "API",
			schedules: "Programaciones",
			campaigns: "Campañas",
			annoucements: "Informativos",
			chats: "Chat Interno",
			financeiro: "Factura",
			files: "Lista de archivos",
			prompts: "OpenAi",
			queueIntegration: "Integraciones",
		  },
		  appBar: {
			user: {
			  profile: "Perfil",
			  logout: "Salir",
			  notRegister: "Sin Chat Interno",
			},
		  },
		},
		queueIntegration: {
			title: "Integraciones",
			table: {
			  id: "ID",
			  type: "Tipo",
			  name: "Nombre",
			  projectName: "Nombre del Proyecto",
			  language: "Lenguaje",
			  lastUpdate: "Ultima atualización",
			  actions: "Acciones",
			},
			buttons: {
			  add: "Adicionar Proyecto",
			},
			searchPlaceholder: "Buscar...",
			confirmationModal: {
			  deleteTitle: "Excluir",
			  deleteMessage:
				"¿Estás seguro? ¡Esta acción no se puede revertir! y será eliminado de las colas y conexiones vinculadas",
			},
		},
		files: {
		  title: "Lista de archivos",
		  table: {
			name: "Nombre",
			contacts: "Contactos",
			actions: "Acción",
		  },
		  toasts: {
			deleted: "¡Lista eliminada con éxito!",
			deletedAll: "Todas las listas se han eliminado con éxito",
		  },
		  buttons: {
			add: "Añadir",
			deleteAll: "Eliminar todo",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteAllTitle: "Eliminar Todo",
			deleteMessage: "¿Estás seguro de que quieres borrar esta lista?",
			deleteAllMessage: "¿Está seguro de que desea eliminar todas las listas?",
		  },
		},
		messagesAPI: {
		  title: "API",
		  textMessage: {
			number: "Número",
			body: "Mensaje",
			token: "Token registrado",
		  },
		  mediaMessage: {
			number: "Número",
			body: "Nombre de archivo",
			media: "Archivo",
			token: "Token registrado",
		  },
		},
		notifications: {
		  noTickets: "Ninguna notificación.",
		  notRegister:"Ningún Registro.",
		},
		quickMessages: {
		  title: "Respuestas Rápidas",
		  searchPlaceholder: "Buscar...",
		  noAttachment: "Sin adjunto",
		  confirmationModal: {
			deleteTitle: "Exclusión",
			deleteMessage: "¡Esta acción es irreversible! ¿Deseas continuar?",
		  },
		  buttons: {
			add: "Añadir",
			attach: "Adjuntar Archivo",
			cancel: "Cancelar",
			edit: "Editar",
		  },
		  toasts: {
			success: "Atajo añadido con éxito!",
			deleted: "Atajo eliminado con éxito!",
		  },
		  dialog: {
			title: "Mensaje Rápido",
			shortcode: "Atajo",
			message: "Respuesta",
			save: "Guardar",
			cancel: "Cancelar",
			geral: "Permitir editar",
			add: "Añadir",
			edit: "Editar",
			visao: "Permitir la visión",
		  },
		  table: {
			shortcode: "Atajo",
			message: "Mensaje",
			actions: "Acciones",
			mediaName: "Nombre de Archivo",
			status: "Estado",
		  },
		},
		messageVariablesPicker: {
		  label: "Variables disponibles",
		  vars: {
			contactFirstName: "Primer Nombre",
			contactName: "Nombre",
			greeting: "Saludo",
			protocolNumber: "Protocolo",
			date: "Fecha",
			hour: "Hora",
		  },
		},
		contactLists: {
		  title: "Listas de Contactos",
		  table: {
			name: "Nombre",
			contacts: "Contactos",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Lista",
		  },
		  dialog: {
			name: "Nombre",
			company: "Empresa",
			okEdit: "Editar",
			okAdd: "Añadir",
			add: "Añadir",
			edit: "Editar",
			cancel: "Cancelar",
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  toasts: {
			deleted: "Registro eliminado",
		  },
		},
		contactListItems: {
		  title: "Contactos",
		  searchPlaceholder: "Buscar",
		  buttons: {
			add: "Nuevo",
			lists: "Listas",
			import: "Importar",
		  },
		  dialog: {
			name: "Nombre",
			number: "Número",
			whatsapp: "Whatsapp",
			email: "Correo electrónico",
			okEdit: "Editar",
			okAdd: "Añadir",
			add: "Añadir",
			edit: "Editar",
			cancel: "Cancelar",
		  },
		  table: {
			name: "Nombre",
			number: "Número",
			whatsapp: "Whatsapp",
			email: "Correo electrónico",
			actions: "Acciones",
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage: "Esta acción no se puede deshacer.",
			importMessage: "¿Desea importar los Contactos de esta hoja de cálculo? ",
			importTitlte: "Importar",
		  },
		  toasts: {
			deleted: "Registro eliminado",
		  },
		},
		campaigns: {
		  title: "Campañas",
		  searchPlaceholder: "Buscar",
		  buttons: {
			add: "Nueva Campaña",
			contactLists: "Listas de Contactos",
		  },
		  table: {
			name: "Nombre",
			whatsapp: "Conexión",
			contactList: "Lista de Contactos",
			status: "Estado",
			scheduledAt: "Programación",
			completedAt: "Terminada",
			confirmation: "Confirmación",
			actions: "Acciones",
		  },
		  dialog: {
			new: "Nueva Campaña",
			update: "Editar Campaña",
			readonly: "Solo vista previa",
			form: {
			  name: "Nombre",
			  message1: "Mensaje 1",
			  message2: "Mensaje 2",
			  message3: "Mensaje 3",
			  message4: "Mensaje 4",
			  message5: "Mensaje 5",
			  confirmationMessage1: "Mensaje de Confirmación 1",
			  confirmationMessage2: "Mensaje de Confirmación 2",
			  confirmationMessage3: "Mensaje de Confirmación 3",
			  confirmationMessage4: "Mensaje de Confirmación 4",
			  confirmationMessage5: "Mensaje de Confirmación 5",
			  messagePlaceholder: "Contenido del Mensaje",
			  whatsapp: "Conexión",
			  status: "Estado",
			  scheduledAt: "Programación",
			  confirmation: "Confirmación",
			  contactList: "Lista de Contacto",
			  tagList: "Lista de Etiquetas",
			  fileList: "Lista de Archivos"
			},
			buttons: {
			  add: "Añadir",
			  edit: "Atualizar",
			  okadd: "Ok",
			  cancel: "Cancelar Disparos",
			  restart: "Reiniciar Disparos",
			  close: "Cerrar",
			  attach: "Adjuntar Archivo",
			},
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  toasts: {
			success: "Operación realizada con éxito",
			cancel: "Campaña cancelada",
			restart: "Campaña reiniciada",
			deleted: "Registro eliminado",
		  },
		},
		announcements: {
		  active: 'Activo',
		  inactive: 'Inactivo',
		  title: "Avisos",
		  searchPlaceholder: "Investigación",
		  buttons: {
			add: "Nuevo Aviso",
			contactLists: "Listas de avisos",
		  },
		  table: {
			priority: "Prioridad",
			title: "Título",
			text: "Texto",
			mediaName: "Archivo",
			status: "Estado",
			actions: "Acciones",
		  },
		  dialog: {
			edit: "Edición de Aviso",
			add: "Nuevo Aviso",
			update: "Editar Aviso",
			readonly: "Vista Previa Solamente",
			form: {
			  priority: "Prioridad",
			  title: "Título",
			  text: "Texto",
			  mediaPath: "Archivo",
			  status: "Estado",
			},
			buttons: {
			  add: "Añadir",
			  edit: "Actualizar",
			  okadd: "Ok",
			  cancel: "Cancelar",
			  close: "Cerrar",
			  attach: "Adjuntar Archivo",
			},
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage: "Esta acción no puede ser revertida.",
		  },
		  toasts: {
			success: "Operación realizada con éxito",
			deleted: "Registro eliminado",
		  },
		},
		campaignsConfig: {
		  title: "Configuraciones de Campañas",
		},
		queues: {
		  title: "Departamentos & Chatbot",
		  table: {
			name: "Nombre",
			color: "Color",
			greeting: "Mensaje de saludo",
			actions: "Acciones",
			orderQueue: "Orden de la fila (bot)",
		  },
		  buttons: {
			add: "Añadir departamento",
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage:
			  "¿Estás seguro? ¡Esta acción no se puede revertir! Las atenciones de esta fila seguirán existiendo, pero ya no tendrán ninguna fila asignada.",
		  },
		},
		queueSelect: {
		  inputLabel: "Departamentos",
		},
		users: {
		  title: "Usuarios",
		  table: {
			name: "Nombre",
			email: "Correo electrónico",
			profile: "Perfil",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Añadir usuario",
		  },
		  toasts: {
			deleted: "usuario eliminado con éxito.",
		  },
		  confirmationModal: {
			deleteTitle: "Excluir",
			deleteMessage:
			  "Todos los datos del usuario se perderán. Los casos abiertos de este usuario se moverán a la cola.",
		  },
		},
		helps: {
		  title: "Centro de Ayuda",
		},
		schedules: {
		  title: "Programaciones",
		  confirmationModal: {
			deleteTitle: "¿Estás seguro de que quieres eliminar esta Programación?",
			deleteMessage: "Esta acción no puede ser revertida.",
		  },
		  table: {
			contact: "Contacto",
			body: "Mensaje",
			sendAt: "Fecha de Programación",
			sentAt: "Fecha de Envío",
			status: "Estado",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Programación",
		  },
		  toasts: {
			deleted: "Programación eliminada con éxito.",
		  },
		},
		tags: {
		  title: "Etiquetas",
		  confirmationModal: {
			deleteTitle: "¿Estás seguro de que quieres eliminar esta etiqueta?",
			deleteMessage: "Esta acción no se puede revertir.",
		  },
		  table: {
			name: "Nombre",
			color: "Color",
			tickets: "Registros Etiquetados",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Etiqueta",
		  },
		  toasts: {
			deleted: "Etiqueta eliminada con éxito.",
		  },
		},
		settings: {
		  success: "Configuraciones guardadas con éxito.",
		  title: "Configuraciones",
		  settings: {
			userCreation: {
			  name: "Creación de usuario",
			  options: {
				enabled: "Activado",
				disabled: "Desactivado",
			  },
			},
		  },
		},
		messagesList: {
		  header: {
			assignedTo: "Asignado a:",
			buttons: {
			  return: "Retornar",
			  resolve: "Resolver",
			  reopen: "Reabrir",
			  accept: "Aceptar",
			},
		  },
		},
		messagesInput: {
		  placeholderOpen: "Escriba un mensaje",
		  placeholderClosed:
			"Reabra o acepte este ticket para enviar un mensaje.",
		  signMessage: "Firmar",
		},
		contactDrawer: {
		  header: "Datos de Contacto",
		  buttons: {
			edit: "Editar Contacto",
		  },
		  extraInfo: "Otras informaciones",
		},
		fileModal: {
		  title: {
			add: "Añadir lista de archivos",
			edit: "Editar lista de archivos",
		  },
		  buttons: {
			okAdd: "Guardar",
			okEdit: "Editar",
			cancel: "Cancelar",
			fileOptions: "Añadir archivo",
		  },
		  form: {
			name: "Nombre de la lista de archivos",
			message: "Detalles de la lista",
			fileOptions: "Lista de archivos",
			extraName: "Mensaje para enviar con archivo",
			extraValue: "Valor de la opción",
		  },
		  success: "¡Lista de archivos guardada con éxito!",
		},
		ticketOptionsMenu: {
		  schedule: "Programar",
		  delete: "Eliminar",
		  transfer: "Transferir",
		  registerAppointment: "Observaciones de Contacto",
		  appointmentsModal: {
			title: "Observaciones de Contacto",
			textarea: "Observación",
			placeholder: "Inserte aquí la información que desea registrar",
		  },
		  confirmationModal: {
			title: "¿Borrar ticket #",
			titleFrom: "del contacto ",
			message:
			  "¡Atención! Todos los mensajes relacionados con el ticket se perderán.",
		  },
		  buttons: {
			delete: "Borrar",
			cancel: "Cancelar",
		  },
		},
		confirmationModal: {
		  buttons: {
			confirm: "Ok",
			cancel: "Cancelar",
		  },
		},
		messageOptionsMenu: {
		  delete: "Eliminar",
		  reply: "Responder",
		  confirmationModal: {
			title: "¿Eliminar mensaje?",
			message: "Esta acción no puede ser revertida.",
		  },
		},
		  plans: {
			  title: "Regístrate",
			  toasts: {
				  success: "Usuario creado con éxito! ¡Haz tu inicio de sesión!",
				  fail: "Error al crear usuario. Verifica los datos proporcionados.",
			  },
			  form: {
				  name: "Plan",
				  users: "Usuarios",
				  connections: "Conexiones",
				  campaigns: "Campañas",
				  schedules: "Horarios",
				  enabled: "Activado",
				  disabled: "Desactivado",
				  clear: "Limpiar",
				  delete: "Eliminar",
				  save: "Guardar",
				  money: "",
				  yes: "Si",
				  no: "No",
			  },
		  },
		backendErrors: {
		  ERR_NO_OTHER_WHATSAPP: "Debe haber al menos un WhatsApp predeterminado.",
		  ERR_NO_DEF_WAPP_FOUND:
			"No se encontró WhatsApp Predeterminado. Verifique la página de conexiones.",
		  ERR_WAPP_NOT_INITIALIZED:
			"Esta sesión de WhatsApp no se ha inicializado. Verifica la página de conexiones.",
		  ERR_WAPP_CHECK_CONTACT:
			"No fue posible verificar el contacto de WhatsApp. Verifica la página de conexiones.",
		  ERR_WAPP_INVALID_CONTACT: "Este no es un número de Whatsapp válido.",
		  ERR_WAPP_DOWNLOAD_MEDIA:
			"No se pudo descargar el contenido multimedia de WhatsApp. Revisa la página de conexiones.",
		  ERR_INVALID_CREDENTIALS:
			"Error de autenticación. Por favor, inténtalo de nuevo.",
		  ERR_SENDING_WAPP_MSG:
			"Error al enviar mensaje de WhatsApp. Verifica la página de conexiones.",
		  ERR_DELETE_WAPP_MSG: "No fue posible eliminar el Mensaje de WhatsApp.",
		  ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
		  ERR_SESSION_EXPIRED: "Sesión caducada. Por favor, ingrese.",
		  ERR_USER_CREATION_DISABLED:
			"La creación de usuario ha sido deshabilitada por el administrador.",
		  ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
		  ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
		  ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
		  ERR_NO_CONTACT_FOUND: "Ningún contacto encontrado con este ID.",
		  ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
		  ERR_NO_USER_FOUND: "Ningún usuario encontrado con este ID.",
		  ERR_NO_WAPP_FOUND: "Ningún WhatsApp encontrado con este ID.",
		  ERR_CREATING_MESSAGE: "Error al crear Mensaje en la base de datos.",
		  ERR_CREATING_TICKET: "Error al crear ticket en la base de datos.",
		  ERR_FETCH_WAPP_MSG:
			"Error al buscar el mensaje en WhatsApp, tal vez sea demasiado antiguo.",
		  ERR_QUEUE_COLOR_ALREADY_EXISTS:
			"Este color ya está en uso, elige otro.",
		  ERR_WAPP_GREETING_REQUIRED:
			"Un mensaje de saludo es obligatorio cuando hay más de una cola.",
		},
	  },
	},
  };
  
  export { messages };